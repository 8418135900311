<template>
  <filter-slot
    :filter="filters"
    :filter-principal="filterPrincipal"
    :total-rows="totalRows"
    :paginate="paginate"
    :start-page="startPage"
    :to-page="toPage"
    @reload="$refs['refAlertTracking'].refresh()"
  >
    <template #table>
      <b-table
        ref="refAlertTracking"
        no-border-collapse
        class="position-relative"
        :fields="fields"
        show-empty
        no-provider-filtering
        sticky-header="50vh"
        primary-key="id"
        responsive="sm"
        :items="trackingProvider"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :current-page="paginate.currentPage"
        :per-page="paginate.perPage"
        :filter="searchInput"
        :busy.sync="isBusy"
      >
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle mr-1" />
            <strong>Loading ...</strong>
          </div>
        </template>
        <template #cell(created_at)="data">
          <div class="text-center">
            <span>{{ data.item.created_at | myGlobalWithHour }}</span>
          </div>
        </template>
        <template #cell(sent_to_call_round)="data">
          <div class="d-flex justify-content-center">
            <b-badge
              v-if="data.item.sent_to_call_round === 1"
              variant="warning"
              class="w-100"
            >
              <span>YES</span>
            </b-badge>
          </div>
        </template>
        <template #cell(lead_name)="data">
          <div>
            <div class="d-flex flex-row">
              <status-account
                :account="data.item"
                :text="false"
              ></status-account>
              <p
                class="p-0 m-0"
                style="padding-left: 4px !important"
                :class="isDarkSkin ? 'text-warning' : 'text-primary'"
              >
                {{ data.item.lead_name }}
              </p>
            </div>
            <p class="m-0 pl-2">
              {{ data.item.account }}
            </p>
          </div>
        </template>
        <template #cell(id_agent_action)="data">
          <b-badge
            :variant="getVariantColor(data.item.id_agent_action)"
            class="w-100"
          >
            <span>{{ data.item.action }}</span>
          </b-badge>
        </template>
        <template #cell(agent_operation)="data">
          <b-badge
            :variant="getVariantColor(data.item.num_agent_operation)"
            class="w-100"
          >
            <span>{{ data.item.agent_operation }}</span>
          </b-badge>
        </template>
      </b-table>
    </template>
  </filter-slot>
</template>

<script>
import IconStatus from "@/views/specialist-digital/views/process-cr/view/components/grids/IconStatus.vue";
import AlertTrackingFilters from "@/views/ce-digital/sub-modules/customer-service/views/alert-tracking/data/alert-tracking.filters";
import AlertTrackingService from "@/views/ce-digital/sub-modules/customer-service/views/alert-tracking/service/alert-tracking.service";
import CedDashboardTracking from "@/views/ce-digital/sub-modules/customer-service/views/dashboard-tracking/service/ced-dashboard-tracking";
import AlertTrackingData from "@/views/ce-digital/sub-modules/customer-service/views/alert-tracking/data/alert-tracking.data";
import ModalActionsTrackingData from "@/views/ce-digital/sub-modules/customer-service/views/alert-tracking/data/modal-actions-tracking.data";
import ModalActionsTrackingFilters from "@/views/ce-digital/sub-modules/customer-service/views/alert-tracking/data/modal-actions-tracking.filters";
import StatusAccount from "@/views/commons/components/clients/stylescomponents/StatusAccount.vue";

export default {
  components: {
    IconStatus,
    StatusAccount,
  },
  name: "DashboardTracking",
  data() {
    return {
      filters: AlertTrackingFilters,
      filterPrincipal: {
        type: "input",
        inputType: "text",
        placeholder: "Search by agent name...",
        model: "",
      },
      fields: AlertTrackingData,
      totalRows: 1,
      paginate: {
        perPage: 10,
        currentPage: 1,
      },
      selectedItem: [],
      startPage: 0,
      toPage: 0,
      isBusy: false,
      sortBy: "created_at",
      sortDesc: false,
      searchInput: "",
      mood: "",
      items: [],
    };
  },
  async created() {
    if (this.$route.meta.mood === 1) {
      this.fields = AlertTrackingData;
      this.filters = AlertTrackingFilters;
      this.filterPrincipal.placeholder = "Search by agent name...";
    } else {
      this.fields = ModalActionsTrackingData;
      this.filters = ModalActionsTrackingFilters;
      this.filterPrincipal.placeholder = "Search by agent or client name...";
    }
    await this.setUpFiltersData();
  },
  computed: {
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
  },
  methods: {
    async trackingProvider(ctx) {
      const params = {
        text: this.filterPrincipal.model,
        date_from: this.filters[0].model,
        date_to: this.filters[1].model,
        per_page: this.paginate.perPage,
        page: this.paginate.currentPage,
        agent_id: this.filters[2].model,
        operation_id: this.filters[3].model,
        orderby: ctx.sortBy ? ctx.sortBy : "created_at",
        order: ctx.sortDesc ? "asc" : "desc",
        action_id: this.filters[3].model,
        language: this.$route.meta.mood === 2 ? this.filters[4].model : "",
        call_round: this.$route.meta.mood === 2 ? this.filters[5].model : "",
      };
      let data = "";
      if (this.$route.meta.mood === 1) {
        data = await AlertTrackingService.searchAlertTrackingCed(params);
      } else {
        data = await AlertTrackingService.searchModalActionsTrackingCed(params);
      }
      this.items = data.data.data;
      this.startPage = data.data.from;
      this.paginate.currentPage = data.data.current_page;
      this.paginate.perPage = data.data.per_page;
      this.nextPage = this.startPage + 1;
      this.endPage = data.data.last_page;
      this.totalRows = data.data.total;
      this.toPage = data.data.to;
      return this.items || [];
    },
    async setUpFiltersData() {
      const dataAgents = await CedDashboardTracking.getSubModuleAgents({
        sub_module_id: this.moduleId,
      });
      if (dataAgents.status === 200) {
        this.filters[2].options = dataAgents.data;
      }
      if (this.$route.meta.mood === 1) {
        this.filters[3].options = [
          {
            id: 1,
            name: "ACCEPT",
          },
          {
            id: 2,
            name: "CANCEL",
          },
          {
            id: 3,
            name: "TRIED",
          },
          {
            id: 4,
            name: "NO OPERATION",
          },
          {
            id: 5,
            name: "END TIME",
          },
        ];
      } else {
        const dataActions = await AlertTrackingService.getActions();
        if (dataActions.status === 200) {
          this.filters[3].options = dataActions.data;
        }
      }
    },
    getVariantColor(id) {
      switch (id) {
        case 1:
          return "success";
        case 2:
          return "danger";
        case 3:
          return "light-success";
        case 4:
          return "light-primary";
        case 5:
          return "secondary";
        default:
          return "primary";
      }
    },
  },
};
</script>

<style scoped></style>
