import { amgApi } from '@/service/axios'

class CedDashboardTrackingService {
  async getDigitalMotives() {
    try {
      const data = await amgApi.get('commons/credit-experts-digital/get-digital-motives')
      return data
    } catch (err) {
      console.log(err)
    }
  }
  async getDigitalActions() {
    try {
      const data = await amgApi.get('commons/credit-experts-digital/get-digital-actions')
      return data
    } catch (err) {
      console.log(err)
    }
  }
  async getDigitalSubmodules() {
    try {
      const data = await amgApi.get('commons/credit-experts-digital/get-digital-submodules')
      return data
    } catch (err) {
      console.log(err)
    }
  }
  async getSubModuleAgents(params) {
    try {
      const data = await amgApi.post('commons/credit-experts-digital/get-submodule-agents', params)
      return data
    } catch (err) {
      console.log(err)
    }
  }
}

export default new CedDashboardTrackingService()
