export default [
  {
    key: "lead_name",
    label: "Client",
  },
  {
    key: "full_name",
    label: "Agent",
  },
  {
    key: "agent_operation",
    label: "Operation",
    class: "text-center",
  },
  {
    key: "created_at",
    label: "Created at",
    sortable: true,
    class: "text-center",
  },
]
