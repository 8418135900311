export default [
  {
    key: "lead_name",
    label: "Client",
  },
  {
    key: "full_name",
    label: "Agent",
  },
  {
    key: "language",
    label: "Language",
  },
  {
    key: "id_agent_action",
    label: "Action",
    thStyle: { textAlign: "center" },
  },
  {
    key: "sent_to_call_round",
    label: "Sent to call round",
    thStyle: { textAlign: "center" },
  },
  {
    key: "created_at",
    label: "Created at",
    thStyle: { textAlign: "center" },
    sortable: true,
  },
]
