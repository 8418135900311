export default [
  {
    type: 'datepicker',
    margin: true,
    showLabel: true,
    label: 'From',
    placeholder: 'Date',
    class: 'font-small-3',
    model: null,
    locale: 'en',
    dateFormatOptions: { year: 'numeric', month: 'numeric', day: 'numeric' },
    cols: 6,
    visible: true,
  },
  {
    type: 'datepicker',
    margin: true,
    showLabel: true,
    label: 'To',
    placeholder: 'Date',
    class: 'font-small-3',
    model: null,
    locale: 'en',
    dateFormatOptions: { year: 'numeric', month: 'numeric', day: 'numeric' },
    cols: 6,
    visible: true,
  },
  {
    type: 'select',
    margin: true,
    showLabel: true,
    label: 'Agent',
    model: null,
    options: [],
    reduce: 'id',
    selectText: 'full_name',
    cols: 12,
    disabled: false,
    visible: true,
  },
  {
    type: 'select',
    margin: true,
    showLabel: true,
    label: 'Action',
    model: null,
    options: [],
    reduce: 'id',
    selectText: 'label',
    cols: 12,
    visible: true,
  },
  {
    type: 'select',
    margin: true,
    showLabel: true,
    label: 'Language',
    model: null,
    options: [{
      id: 'EN',
      name: 'EN',
    },
    {
      id: 'ES',
      name: 'ES',
    }],
    reduce: 'id',
    selectText: 'name',
    cols: 12,
    visible: true,
  },
  {
    type: 'select',
    margin: true,
    showLabel: true,
    label: 'Sent to call round',
    model: null,
    options: [{
      id: 1,
      name: 'YES',
    },
    {
      id: 0,
      name: 'NO',
    }],
    reduce: 'id',
    selectText: 'name',
    cols: 12,
    visible: true,
  },
]
