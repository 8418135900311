import { amgApi } from "@/service/axios"

class AlertTrackingService {
  async searchAlertTrackingCed(params) {
    const data = await amgApi.post(
      "credit-experts-digital/alert/search-alert-tracking-ced",
      params
    )
    return data
  }
  async searchModalActionsTrackingCed(params) {
    const data = await amgApi.post(
      "credit-experts-digital/alert/search-modal-actions-tracking-ced",
      params
    )
    return data
  }
  async getActions(params) {
    const data = await amgApi.get(
      "credit-experts-digital/alert/get-actions",
      params
    )
    return data
  }
  async searchWaitingAlerts(params) {
    const data = await amgApi.post(
      "credit-experts-digital/alert/waiting",
      params
    )
    return data
  }
}
export default new AlertTrackingService()
